<template>
  <v-container fluid>
    <div v-if="!idEstudio" class="d-flex flex-wrap py-2">
      <v-btn class="ma-1" color="primary" small @click="getEstudios">
        <v-icon left>mdi-reload</v-icon>Recargar
      </v-btn>
      <v-switch
        v-model="mostrarRechazados"
        hide-details
        label="Mostrar rechazados"
        class="mt-0 ml-1 align-self-center"
      />
      <v-spacer />

      <MensajesSinLeer :key="incrementId" @reload="getEstudios" />
    </div>
    <div v-if="!idEstudio" class="pa-0">
      <v-card-subtitle class="pa-0">
        Los estudios nuevos se envían en
        <router-link
          style="color: var(--v-secondary-base); text-decoration: none"
          to="/borradores"
          >borradores</router-link
        >
      </v-card-subtitle>
    </div>
    <v-card>
      <v-data-table
        :headers="estudiosHeaders"
        :items="estudiosFiltrados"
        :loading="loading"
        :single-select="false"
        item-key="idEstudio"
        checkbox-color="secondary"
        show-select
        v-model="selected"
        :calculate-widths="true"
        class="fixed-checkbox"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [25, 50, -1],
        }"
      >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="estudiosFiltrados"
            v-model="inlineFilters"
          ></table-filters>
        </template>

        <template v-slot:item.estado="{ item }">
          <status-chip :value="item.estado" />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="warning" right small v-if="item.fav" v-on="on" v-bind="attrs" >mdi-star</v-icon>
            </template>
            En favoritos
          </v-tooltip>
        </template>

        <template v-slot:item.nLlamadas="{ item }">
          <!-- <status-chip :value="item.nLlamadas" /> -->
          <v-chip label v-if="item.nLlamadas > 0" small class="v-chip--active" outlined color="primary">
            <v-icon left>mdi-phone</v-icon>
            {{ item.nLlamadas }}
          </v-chip>
        </template>

        <template v-slot:item.alertas="{ item }">
          <v-menu v-if="item.nAlertas > 0" offset-y offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-badge color="secondary" overlap dot>
                <v-btn
                  @click.stop="getAlerts(item.idEstudio, 'pendientes')"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <v-icon small>mdi-bell-outline</v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card>
              <v-list dense>
                <v-list-item
                  :to="`/detalles_estudio?idEstudio=${alert.idEstudio}`"
                  v-for="alert in alertas"
                  :key="alert.idAlerta"
                >
                  <v-list-item-content>
                    {{ alert.idEstudio }} - {{ alert.resumen }}
                  </v-list-item-content>
                  <v-list-item-action-text>{{
                    parseDate(alert.fechaAlerta, false, true, true)
                  }}</v-list-item-action-text>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>

          <v-menu v-if="item.nAlertasActivas > 0" offset-y offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-badge color="error darken-2" overlap dot>
                <v-btn
                  @click.stop="getAlerts(item.idEstudio, 'activas')"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <v-icon small>mdi-bell-ring</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <v-card>
              <v-list dense min-width="250">
                <v-list-item
                  :to="`/detalles_estudio?idEstudio=${alert.idEstudio}`"
                  v-for="alert in alertas"
                  :key="alert.idAlerta"
                >
                  <v-list-item-content>
                    {{ alert.idEstudio }} - {{ alert.resumen }}
                  </v-list-item-content>
                  <v-list-item-action-text>{{
                    parseDate(alert.fechaAlerta, false, true, true)
                  }}</v-list-item-action-text>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>

          <v-badge dot overlap v-if="Number(item.nMensajesSinLeer) > 0">
            <v-btn
              :to="`/detalles_estudio?idEstudio=${item.idEstudio}`"
              icon
              small
            >
              <v-icon small>mdi-message-alert-outline</v-icon>
            </v-btn>
          </v-badge>
        </template>

        <template v-slot:item.estadoInterno="{ item }">
          <status-chip v-if="item.estadoInterno" :value="item.estadoInterno" />
        </template>
        <template v-slot:item.tipoEstudio="{ item }">
          <v-chip label outlined :color="item.colorTipoEstudio">
            <v-icon left>{{ item.iconTipoEstudio }}</v-icon>
            {{ item.tipoEstudio }}
          </v-chip>
        </template>

        <template v-slot:item.enviadoEn="{ item }">
          <span v-text="parseDate(item.enviadoEn, false, true, true)" />
        </template>

        <template v-slot:item.ultimoContacto="{ item }">
          <v-chip outlined v-text="parseDate(item.ultimoContacto, false, true, true) || 'Nunca'" />
        </template>

        <template v-slot:item.datos.suministro.tarifa="{ item }">
          <v-chip small v-text="item.datos.suministro.tarifa" label />
        </template>

        <template v-slot:item.empresa="{ item }">
          <empresa-chip small :value="item.empresa" label />
        </template>

        <template v-slot:item.formato="{ item }">
          <v-chip label outlined small color="info">
            <v-icon left>mdi-file-document-outline</v-icon>
            {{ item.formato }}
          </v-chip>
        </template>

        <template v-slot:item.datos.potenciaEstudio="{ item }">
          <v-chip label outlined v-if="item.datos.potenciaEstudio != null" >
            <v-icon left>mdi-solar-panel</v-icon>
            {{ item.datos.potenciaEstudio }}
          </v-chip>
        </template>

        <template v-slot:item.idEstudio="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                color="secondary"
                :to="`/detalles_estudio?idEstudio=${item.idEstudio}`"
                text
                >{{ item.idEstudio }}</v-btn
              >
            </template>
            Detalles estudio
          </v-tooltip>
        </template>

        <template v-slot:item.acciones="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                color="secondary"
                target="_blank"
                :href="
                  `https://www.google.com/maps/search/?api=1&query=${item.datos.latitud}%2C${item.datos.longitud}`
                "
              >
                <v-icon>mdi-google-maps</v-icon>
              </v-btn>
            </template>
            Abrir en Google Maps
          </v-tooltip>

          <v-btn
            color="secondary"
            @click.stop="detailsId = item.idEstudio"
            outlined
            class="rounded-pill"
            small
          >
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>

          <v-btn
            color="secondary"
            @click.stop="toggleFav(item)"
            class="rounded-pill"
            icon
            small
          >
            <v-icon v-if="!item.fav" >mdi-star-outline</v-icon>
            <v-icon v-if="item.fav" >mdi-star</v-icon>
          </v-btn>

        </template>
      </v-data-table>
    </v-card>

    <v-dialog persistent max-width="600px" width="100%" v-model="cambiarEstado">
      <cambiar-estado
        :key="cambiarEstado"
        @reload="getEstudios"
        :estudios="selected.map((c) => c.idEstudio)"
        @close="cambiarEstado = false"
      />
    </v-dialog>

    {{ detailsId }}

    <v-dialog
      transition="dialog-bottom-transition"
      fullscreen
      hide-overlay
      :value="detailsId"
      @input="(v) => (v === false ? (detailsId = null) : null)"
    >
      <v-toolbar>
        <v-toolbar-title>Estudio {{ detailsId }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click.stop="detailsId = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <DetallesEstudio
          :key="detailsId"
          :_idEstudio="detailsId"
          @reload="getEstudios"
          @close="cambiarEstado = false"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { perColumnFilter, parseDate, downloadFile } from "@/utils/index.js";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    EmpresaChip: () => import("@/components/EmpresaChip.vue"),
    DragAndDrop: () => import("@/components/DragAndDrop.vue"),
    CambiarEstado: () => import("@/components/CambiarEstado.vue"),
    MensajesSinLeer: () => import("@/components/MensajesSinLeer.vue"),
    EnviarEstudio: () => import("@/views/EnviarEstudio.vue"),
    DetallesEstudio: () => import("@/views/DetallesEstudio.vue"),
  },
  props: {
    idEstudio: null,
    eliminados: Boolean,
  },
  mounted() {
    this.getEstudios();
  },
  computed: {
    estudiosFiltrados() {
      return this.mostrarRechazados
        ? this.estudios
        : this.estudios.filter((e) => e.estado != "Rechazado");
    },
  },
  methods: {
    async getEstudios() {
      this.loading = true;
      const { data: estudios } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/estudios.php`,
        params: {
          idUsuario: this.$store.getters.getTokenPayload.idUsuario,
          idEstudio: this.idEstudio,
          eliminados: this.eliminados ? 1 : 0,
          borradores: 0,
        },
      });
      this.estudios = estudios.map((e) => ({ ...e, datos: JSON.parse(e.datos) }))
      this.incrementId++;
      this.loading = false;
    },
    async getAlerts(idEstudio, tipo) {
      let { data: alerts } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/cronograma/alertas.php`,
        params: { idEstudio },
      });

      const alertas = {
        pendientes: alerts.filter((a) => a.enviado == "0"),
        activas: alerts.filter((a) => a.enviado == "1"),
      };
      if (tipo == "pendientes") this.alertas = alertas.pendientes;
      else this.alertas = alertas.activas;
    },
    async autoestudio(estudios) {
      if (estudios)
        this.selected = [this.estudios.find((e) => e.idEstudio == estudios)];
      this.haciendoAutoestudios = true;
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/autoestudio.php`,
        data: {
          estudios: this.selected.map((e) => e.idEstudio).join(","),
        },
      });
      this.selected = [];
      this.haciendoAutoestudios = false;
      this.getEstudios();
    },
    async toggleFav({idEstudio, fav}) {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/favoritos.php`,
        data: {
          idEstudio
        },
      });
      this.getEstudios();
      this.$root.$emit('snack', `Estudio ${idEstudio} ${fav ? 'eliminado de' : 'añadido a'} favoritos` )
      this.$root.$emit('getFav')
    },
    parseDate,
  },
  data() {
    return {
      haciendoAutoestudios: false,
      idSubida: null,
      idDetalles: null,
      modalDatos: false,
      mostrarRechazados: false,
      inlineFilters: {},
      cambiarEstado: false,
      incrementId: 0,
      detailsId: null,
      alertas: [],
      estudiosHeaders: [
        { text: "Estado", value: "estado", dataType: "select" },
        {
          text: "Alertas",
          value: "alertas",
          filterable: false,
          sortable: false,
        },
        {
          text: "Llamadas",
          value: "nLlamadas",
        },
        {
          text: "Estado interno",
          value: "estadoInterno",
          dataType: "select",
          acceso: "EDITAR_ESTUDIOS",
        },
        { text: "Nº Estudio", value: "idEstudio", align: "end" },
        { text: "Acciones", value: "acciones", sortable: false, filterable: false, align: "center", },
        { text: "Potencia estudio", value: "datos.potenciaEstudio", },
        { text: "Precio Pico", value: "datos.precioPico", },
        { text: "Precio Total", value: "datos.precioTotal", },
        { text: "Nombre estudio", value: "datos.nombreProyecto" },
        { text: "Tipo estudio", value: "tipoEstudio", dataType: "select" },
        {
          text: "Comercial",
          value: "comercial",
          cellClass: "pa-2 text-no-wrap font-weight-bold",
          sub:
            this.$store.getters.getTokenPayload.esCanal ||
            this.$root.acceso("VER_TODOS_ESTUDIOS"),
        },
        {
          text: "SubComercial",
          value: "subComercial",
          sub:
            this.$store.getters.getTokenPayload.esCanal ||
            this.$root.acceso("VER_TODOS_ESTUDIOS"),
        },
        {
          text: "Portal",
          value: "empresa",
          acceso: "VER_TODOS_ESTUDIOS",
          dataType: "select",
        },
        { text: "Formato", value: "formato", acceso: "VER_TODOS_ESTUDIOS" },
        { text: "Fecha Envío", value: "enviadoEn", dataType: "date" },
        { text: "Último contacto", value: "ultimoContacto", dataType: "date" },
        { text: "CUPS", value: "datos.suministro.cups" },
        {
          text: "Tarifa",
          value: "datos.suministro.tarifa",
          dataType: "select",
        },
        // { text: "Latitud", value: "lat" },
        // { text: "Longitud", value: "lon" },
        // { text: "Inclinación", value: "inclinacion" },
        // { text: "Azimut", value: "azimut" },
      ]
        .filter((h) => {
          return (
            (h.sub == null || h.sub) &&
            (h.acceso == null || this.$root.acceso(h.acceso))
          );
        })
        .map((header) => {
          return {
            class: "text-no-wrap sticky-header",
            cellClass: "pa-2 text-no-wrap",
            filter: (value) =>
              perColumnFilter(
                value,
                this.inlineFilters[header.value],
                header.dataType
              ),
            dataType: "text",
            ...header,
          };
        }),
      estudios: [],
      selected: [],
      loading: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.detailsId != null && to.name != "EnviarEstudio") {
      this.detailsId = null;
      next(false);
      return;
    }
    next();
  },
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
  background: #f1f1f1 !important;
}
</style>
